import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { TextbookModel } from "@/models";
import { TeCommon } from "../Common";

type response = { [key: string]: unknown };

@Component<ApTeacherTextbookIndexController>({
    components: {
        Header,Footer
    }
})
export default class ApTeacherTextbookIndexController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private textbookList: object = {};
    private gradeList: object = {};
    private token = window.localStorage.getItem('teacherToken') as string;
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private grade = typeof(this.$route.query.grade) === 'undefined' ? 'K1' : this.$route.query.grade as string;
    private classColor: { [key: string]: string } = {
        'K1': 'c-tab-k1',
        'K2': 'c-tab-k2',
        'K3': 'c-tab-k3',
        'P1': 'c-tab-p1',
        'P2': 'c-tab-p2',
        'P3': 'c-tab-p3',
        'P4': 'c-tab-p4',
        'P5': 'c-tab-p5',
        'P6': 'c-tab-p6',
    };

    public async created() {
        this.getList();
        this.gradeList = await TeCommon.getGrade();
    }

    public async getList() {
        const item: { [key: string]: string } = {
            grade: this.grade,
            tid: this.tid,
            token: this.token,
        }
        const data = await TextbookModel.teGetList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.textbookList = data.list as object;
    }

    private changeGrade(grade: string) {
        this.grade = grade;
        this.getList();
    }
}